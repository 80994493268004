import React from "react";
import "./second.css";

const Second = () => {
  return (
    <div className="second-container"
      // style={{
      //     backgroundImage: 'url("/back.jpg")',
      //     backgroundSize: 'cover',
      //     backgroundPosition: 'center',
      //     backgroundRepeat: 'no-repeat',
      //   }}
        >
      {/* Left Section */}
      <div className="second-left">
        <div className="logo-container">
          <img src="/Social2.png" alt="Logo" className="logo" />
        </div>
      </div>

      {/* Right Section */}
      <div className="second-right-custom">
  <div className="text-container-custom">
    <h2>Who We Are</h2>
    <p>
      We're your fitness bestie who's always up for trying that crazy new
      trampoline class or finding you the perfect badminton partner. Born in
      Bangalore's vibrant fitness scene, we're turning "ugh, I should workout"
      into "can't wait for my next class!" Whether you're escaping boring gym
      routines or looking for sports partners, we're here to make fitness feel
      like hanging out with friends. Because let's face it – working out is
      better when it's social.
    </p>
  </div>
</div>
    </div>
  );
};

export default Second;
