import React, { useState } from 'react';
import './Third.css';

const Third = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      mobileNumber: formData.mobileNumber || 'N/A', // Optional field, default to 'N/A' if empty
      email: formData.email,
    };
  
    // Log data before sending
    console.log('Sending data:', data);
  
    try {
      const response = await fetch('https://server-sweatsocial.vercel.app/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Send form data
      });
  
      // Log response status
      console.log('Response status:', response.status);
  
      if (response.ok) {
        const result = await response.json(); // Parse JSON response
        console.log('Response:', result);
        alert('Form submitted successfully!');
      } else {
        const errorData = await response.json();
        console.log('Error:', errorData);
        alert('Error submitting form.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form.');
    }
  };  
  
  return (
    <div className="third-component-wrapper"
        //   style={{
        //   backgroundImage: 'url("/back.jpg")',
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        //   backgroundRepeat: 'no-repeat',
        // }}
        >

      {/* Main Content */}
      <div className="third-content">

  <div className="third-form-container">
    <h2>Ready to get a FREE session?</h2>
      <h2> Sign Up Here</h2>
    <form onSubmit={handleSubmit}>
      <div className="third-form-row">
        <div className="third-form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="third-form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="third-form-group">
        <label htmlFor="mobileNumber">Mobile Number (optional)</label>
        <input
          type="tel"
          id="mobileNumber"
          name="mobileNumber"
          placeholder="Mobile Number (optional)"
          value={formData.mobileNumber}
          onChange={handleChange}
        />
      </div>
      <div className="third-form-group">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit" className="third-sign-up-button">
        Sign Up Here
      </button>
    </form>
    {/* Add the horizontal line here */}
    {/* <div className="horizontal-line"></div> */}
  </div>
  <div className="secondary-image">
    <img src="pilates.jpg" 
    alt="Secondary Image" />
  </div>
  <div className="third-image-container">
    <img src="bat.jpg" alt="Pickleball Player" />
  </div>
  </div>
    </div>
  );
};

export default Third;
