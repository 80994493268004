import React from 'react';
import './Footer.css';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { useMediaQuery } from 'react-responsive';

const navIcon1 = "/nav-icon1.svg";
const navIcon2 = "/nav-icon2.svg";
const navIcon3 = "/nav-icon3.svg";

const Footer = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <footer className="footer-container">
      <div className={`footer-content ${isTabletOrMobile ? 'column-layout' : 'row-layout'}`}>
        {/* Left Section */}
        <div className="footer-left">
          <div className="footer-title-wrapper">
            <p className="footer-title">It is time to try something new..</p>
          </div>
          <div className="footer-logo-wrapper">
            <img src="/Social.png" alt="SweatSocial Logo" className="footer-logo" />
          </div>

          {/* Social Media Icons Section */}
          <div className="footer-social-wrapper">
            <a href="https://x.com/sweatsocialclub" target="_blank" rel="noopener noreferrer">
              <img src={navIcon1} alt="Twitter" className="social-icon" />
            </a>
            {/* <a href="https://www.facebook.com/ITChampsSAP/" target="_blank" rel="noopener noreferrer">
              <img src={navIcon2} alt="Facebook" className="social-icon" />
            </a> */}
            <a href="https://www.instagram.com/sweat.social.club/" target="_blank" rel="noopener noreferrer">
              <img src={navIcon3} alt="Instagram" className="social-icon" />
            </a>
          </div>

          {/* Copyright Section */}
          <div className="footer-copyright">
            <p>&copy; 2024 SweatSocial. All Rights Reserved.</p>
          </div>
        </div>

        {/* Right Section */}
        <div className="footer-right">
          <h3 className="footer-contact-title">Get in Touch</h3>
          <div className="contact-item">
            <PhoneIcon className="contact-icon" />
            <span className="contact-text">+91 63605989824</span>
          </div>
          <div className="contact-item">
            <EmailIcon className="contact-icon" />
            <span className="contact-text">ak@sweatsocial.club</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
