import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import Second from './second';
import Third from './Third';
import Footer from './Footer';
import Navbar from './Navbar';
import '../App.css';

const Home = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const thirdRef = useRef(null);

  const scrollToThird = () => {
    thirdRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    const baseTargetDate = new Date('2024-12-20T00:00:00').getTime();
    const targetDate = baseTargetDate + 2 * 24 * 60 * 60 * 1000;

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      } else {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Navbar scrollToThird={scrollToThird} />
      <div className="home-container-custom">
        <div className="background-overlay-container">
          <div className="background-image-container">
            <img
              src="/First.png"
              alt="Background"
              className="background-image-custom"
            />
          </div>
          <div className="hero-section-custom">
            <div className="logos-wrapper">
              <img src="/Social2.png" alt="Logo 2" className="logo-custom" />
              <img src="/Social.png" alt="Logo 1" className="logo-custom-first" />
            </div>
            <h2>YOUR WAIT IS ALMOST OVER</h2>
            <div className="countdown-timer-custom">
              <div>
                {String(timeLeft.days).padStart(2, '0')}
                <span>Days</span>
              </div>
              <div>
                {String(timeLeft.hours).padStart(2, '0')}
                <span>Hours</span>
              </div>
              <div>
                {String(timeLeft.minutes).padStart(2, '0')}
                <span>Minutes</span>
              </div>
              <div>
                {String(timeLeft.seconds).padStart(2, '0')}
                <span>Seconds</span>
              </div>
            </div>
            <button className="join-waitlist-button-custom" onClick={scrollToThird}>
              Join our Waitlist
            </button>
          </div>
        </div>
      </div>
      <div>
        <Second />
      </div>
      <div ref={thirdRef}>
        <Third />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
