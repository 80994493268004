import React from 'react';
import './Navbar.css';

const Navbar = ({ scrollToThird }) => {
  return (
    <nav className="navbar">
      <div className="logo-container">
        <img
          src={`${process.env.PUBLIC_URL}/Social2.png`}
          alt="Waitless Logo"
          className="Navlogo"
        />
      </div>
      <ul className="nav-links">
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#contact" onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            scrollToThird(); // Trigger smooth scroll
          }}>
            Contact Us
          </a>
        </li>
      </ul>
      <a href="#join" className="join-now-button">Join Now</a>
    </nav>
  );
};

export default Navbar;
